<template>
  <!--统计图-->
    <div
      :id="id"
      :style="'width:'+(200 + table_data.length*15)+'px; height:100%;'"
    ></div>
</template>

<script>
export default {
  props: {
    id:'',
    table_data:{
       type:Array,
       default:[]
    }
  },
  data() {
    return {};
  },
  //初始化绘制图表
  mounted() {
    this.drawLine();
  },
  watch:{//监听从新绘制
    table_data(){
      this.$nextTick(()=>{
        this.drawLine();
      })
    }
  },
  methods: {
    drawLine() {
      console.log(this.table_data,"3");
      //基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(this.id));
      let name_data = [];
      let value_data = [];

      myChart.resize({width:200 + this.table_data.length*18.5})

      // if(this.id=='zongbu'){//总部看板
      //   for(let i=0;i<this.table_data.length;i++){
      //   let every_name = this.table_data[i].分支机构;
      //   // console.log(every_name,"every_name")
      //   //this.table_data[i].分支机构!='电商2'
      //   if(every_name.indexOf('合计')==-1){//设置不显示名单=>合计
      //       every_name=every_name.substring(0,(every_name.indexOf('分店')!=-1?every_name.indexOf('分店'):every_name.length));
      //       // console.log(every_name,"截取")
      //       let every_value = 0;
      //       if(this.table_data[i].销售额>0){
      //         every_value = this.table_data[i].销售额
      //       }
      //       name_data.push(every_name);
      //       value_data.push(every_value);
      //     }
      //   }
      // }else{//门店看板
      //   console.log(this.table_data);
          for(let i=0;i<this.table_data.length;i++){
  
              name_data.push(this.table_data[i].date);
              value_data.push(this.table_data[i].count);
            
          }
      // }
     
      //绘制
      myChart.setOption({
        color: {
          x:1,
          y:0,
          x2:0,
          y2:1,
          colorStops:[
            {offset:0,color:'#C808A2'},//0%处的颜色
            {offset:0.5,color:'#7270E6'},//100%处的颜色
            {offset:0.8,color:'#42A3FE'},
          ],
          globalCoord: false, 
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "5%",
          right: "3%",
          bottom: "3%",
          top:"10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: name_data,
            axisLabel: {  
               interval:0,  
               rotate:40  
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            // name: "直接访问",
            type: "bar",
            barWidth: "50%",
            data: value_data,
            itemStyle:{
              normal:{
                barBorderRadius:[15,15,0,0]
              }
            }
          },
        ],
      });
    },
  },
};
</script>